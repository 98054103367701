import React, { useState, useEffect } from "react";
import Filters from "./Filters";
import ColourName from "./ColourName";
import Viewport from "./Viewport";
import Swatch from "./Swatch";
import CustomColorSelection from "./CustomColorSelection";
import { useWindowSize } from "../../utils/useWindowSize";

import xtremeDesertTile from "../../images/xtreme/xtreme-desert-tile-cc.jpg";
import xtremeDesertBoard from "../../images/xtreme/xtreme-desert-board-cc.jpg";
import xtremeDesert2 from "../../images/xtreme/xtreme-desert-img-2.jpg";
import xtremeDesert3 from "../../images/xtreme/xtreme-desert-img-3.jpg";
import xtremeDesert6 from "../../images/xtreme/xtreme-desert-img-6.jpg";
import xtremeDesert7 from "../../images/xtreme/xtreme-desert-img-7.jpg";

import xtremeRusticTile from "../../images/xtreme/xtreme-rustic-tile-cc.jpg";
import xtremeRusticBoard from "../../images/xtreme/xtreme-rustic-board-cc.jpg";
import xtremeRustic2 from "../../images/xtreme/xtreme-rustic-img-2.jpg";
import xtremeRustic3 from "../../images/xtreme/xtreme-rustic-img-3.jpg";
import xtremeRustic6 from "../../images/xtreme/xtreme-rustic-img-6.jpg";
import xtremeRustic7 from "../../images/xtreme/xtreme-rustic-img-7.jpg";

import xtremeGraphiteTile from "../../images/xtreme/xtreme-graphite-tile-cc.jpg";
import xtremeGraphiteBoard from "../../images/xtreme/xtreme-graphite-board-cc.jpg";
import xtremeGraphite2 from "../../images/xtreme/xtreme-graphite-img-2.jpg";
import xtremeGraphite3 from "../../images/xtreme/xtreme-graphite-img-3.jpg";
import xtremeGraphite6 from "../../images/xtreme/xtreme-graphite-img-6.jpg";
import xtremeGraphite7 from "../../images/xtreme/xtreme-graphite-img-7.jpg";

import xtremeBasaltTile from "../../images/xtreme/xtreme-basalt-tile-cc.jpg";
import xtremeBasaltBoard from "../../images/xtreme/xtreme-basalt-board-cc.jpg";
import xtremeBasalt2 from "../../images/xtreme/xtreme-basalt-img-2.jpg";
import xtremeBasalt3 from "../../images/xtreme/xtreme-basalt-img-3.jpg";
import xtremeBasalt6 from "../../images/xtreme/xtreme-basalt-img-6.jpg";
import xtremeBasalt7 from "../../images/xtreme/xtreme-basalt-img-7.jpg";

import xtremeAshTile from "../../images/xtreme/xtreme-ash-tile-cc.jpg";
import xtremeAshBoard from "../../images/xtreme/xtreme-ash-board-cc.jpg";
import xtremeAsh2 from "../../images/xtreme/xtreme-ash-img-2.jpg";
import xtremeAsh3 from "../../images/xtreme/xtreme-ash-img-3.jpg";
import xtremeAsh6 from "../../images/xtreme/xtreme-ash-img-6.jpg";
import xtremeAsh7 from "../../images/xtreme/xtreme-ash-img-7.jpg";

import xtremeRiverstoneTile from "../../images/xtreme/xtreme-riverstone-tile-cc.jpg";
import xtremeRiverstoneBoard from "../../images/xtreme/xtreme-riverstone-board-cc.jpg";
import xtremeRiverstone2 from "../../images/xtreme/xtreme-riverstone-img-2.jpg";
import xtremeRiverstone3 from "../../images/xtreme/xtreme-riverstone-img-3.jpg";
import xtremeRiverstone6 from "../../images/xtreme/xtreme-riverstone-img-6.jpg";
import xtremeRiverstone7 from "../../images/xtreme/xtreme-riverstone-img-7.jpg";


import xtremeLightOakTile from "../../images/xtreme/xtreme-lightoak-tile-cc.jpg";
import xtremeLightOakBoard from "../../images/xtreme/xtreme-lightoak-board-cc.jpg";
import xtremeLightOak2 from "../../images/xtreme/xtreme-lightoak-img-2.jpg";
import xtremeLightOak3 from "../../images/xtreme/xtreme-lightoak-img-3.jpg";
import xtremeLightOak6 from "../../images/xtreme/xtreme-lightoak-img-6.jpg";
import xtremeLightOak7 from "../../images/xtreme/xtreme-lightoak-img-7.jpg";

import originalMahoBoard from "../../images/original/original-maho-board.jpg";
import originalMahoTile from "../../images/original/original-maho-tile.jpg";
import originalMaho1 from "../../images/original/original-maho-img-2.jpg";
import originalMaho2 from "../../images/original/original-maho-img-3.jpg";
import originalMaho3 from "../../images/original/original-maho-img-6.jpg";
import originalMaho4 from "../../images/original/original-maho-img-7.jpg";

import originalWeathBoard from "../../images/original/original-weath-board.jpg";
import originalWeathTile from "../../images/original/original-weath-tile.jpg";
import originalWeath1 from "../../images/original/original-weath-img-2.jpg";
import originalWeath2 from "../../images/original/original-weath-img-3.jpg";
import originalWeath3 from "../../images/original/original-weath-img-6.jpg";
import originalWeath4 from "../../images/original/original-weath-img-7.jpg";

import originalSlateBoard from "../../images/original/original-slate-board.jpg";
import originalSlateTile from "../../images/original/original-slate-tile.jpg";
import originalSlate1 from "../../images/original/original-slate-img-2.jpg";
import originalSlate2 from "../../images/original/original-slate-img-3.jpg";
import originalSlate3 from "../../images/original/original-slate-img-6.jpg";
import originalSlate4 from "../../images/original/original-slate-img-7.jpg";

import originalWalnutBoard from "../../images/original/original-walnut-board.jpg";
import originalWalnutTile from "../../images/original/original-walnut-tile.jpg";
import originalWalnut1 from "../../images/original/original-walnut-img-2.jpg";
import originalWalnut2 from "../../images/original/original-walnut-img-3.jpg";
import originalWalnut3 from "../../images/original/original-walnut-img-6.jpg";
import originalWalnut4 from "../../images/original/original-walnut-img-7.jpg";

import originalBBBoard from "../../images/original/original-bb-board.jpg";
import originalbbTile from "../../images/original/original-bb-tile.jpg";
import originalBB2 from "../../images/original/original-bb-img-2.jpg";
import originalBB3 from "../../images/original/original-bb-img-3.jpg";
import originalBB4 from "../../images/original/original-bb-img-4.jpg";
import originalBB5 from "../../images/original/original-bb-img-5.jpg";

import originalCustWhiteBoard from "../../images/original/custom/custom-white-board.jpg";
import originalCustWhiteTile from "../../images/original/custom/swatch-white.jpg";
import originalCustWhite1 from "../../images/original/custom/Image1-White.jpg";
import originalCustWhite2 from "../../images/original/custom/Image2-White.jpg";
import originalCustWhite3 from "../../images/original/custom/Image3-White.jpg";
import originalCustWhite4 from "../../images/original/custom/Image4-White.jpg";

import originalCustBeigeBoard from "../../images/original/custom/custom-beige-board.jpg";
import originalCustBeigeTile from "../../images/original/custom/swatch-beige.jpg";
import originalCustBeige1 from "../../images/original/custom/Image1-Beige.jpg";
import originalCustBeige2 from "../../images/original/custom/Image2-Beige.jpg";
import originalCustBeige3 from "../../images/original/custom/Image3-Beige.jpg";
import originalCustBeige4 from "../../images/original/custom/Image4-Beige.jpg";

import originalCustBrownBoard from "../../images/original/custom/custom-brown-board.jpg";
import originalCustBrownTile from "../../images/original/custom/swatch-brown.jpg";
import originalCustBrown1 from "../../images/original/custom/Image1-Brown.jpg";
import originalCustBrown2 from "../../images/original/custom/Image2-Brown.jpg";
import originalCustBrown3 from "../../images/original/custom/Image3-Brown.jpg";
import originalCustBrown4 from "../../images/original/custom/Image4-Brown.jpg";

import originalCustDeepTeaBoard from "../../images/original/custom/custom-deep-tea-board.jpg";
import originalCustDeepTeaTile from "../../images/original/custom/swatch-deep-tea.jpg";
import originalCustDeepTea1 from "../../images/original/custom/Image1-Deep-Tea.jpg";
import originalCustDeepTea2 from "../../images/original/custom/Image2-Deep-Tea.jpg";
import originalCustDeepTea3 from "../../images/original/custom/Image3-Deep-Tea.jpg";
import originalCustDeepTea4 from "../../images/original/custom/Image4-Deep-Tea.jpg";

import originalCustGreyBoard from "../../images/original/custom/custom-grey-board.jpg";
import originalCustGreyTile from "../../images/original/custom/swatch-grey.jpg";
import originalCustGrey1 from "../../images/original/custom/Image1-Grey.jpg";
import originalCustGrey2 from "../../images/original/custom/Image2-Grey.jpg";
import originalCustGrey3 from "../../images/original/custom/Image3-Grey.jpg";
import originalCustGrey4 from "../../images/original/custom/Image4-Grey.jpg";

import originalCustLightGreyBoard from "../../images/original/custom/custom-light-grey-board.jpg";
import originalCustLightGreyTile from "../../images/original/custom/swatch-light-grey.jpg";
import originalCustLightGrey1 from "../../images/original/custom/Image1-Light-Grey.jpg";
import originalCustLightGrey2 from "../../images/original/custom/Image2-Light-Grey.jpg";
import originalCustLightGrey3 from "../../images/original/custom/Image3-Light-Grey.jpg";
import originalCustLightGrey4 from "../../images/original/custom/Image4-Light-Grey.jpg";

import originalCustLightTeaBoard from "../../images/original/custom/custom-light-tea-board.jpg";
import originalCustLightTeaTile from "../../images/original/custom/swatch-light-tea.jpg";
import originalCustLightTea1 from "../../images/original/custom/Image1-Light-Tea.jpg";
import originalCustLightTea2 from "../../images/original/custom/Image2-Light-Tea.jpg";
import originalCustLightTea3 from "../../images/original/custom/Image3-Light-Tea.jpg";
import originalCustLightTea4 from "../../images/original/custom/Image4-Light-Tea.jpg";

import originalCustPlatinumBoard from "../../images/original/custom/custom-platinum-board.jpg";
import originalCustPlatinumTile from "../../images/original/custom/swatch-platinum.jpg";
import originalCustPlatinum1 from "../../images/original/custom/Image1-Platinum.jpg";
import originalCustPlatinum2 from "../../images/original/custom/Image2-Platinum.jpg";
import originalCustPlatinum3 from "../../images/original/custom/Image3-Platinum.jpg";
import originalCustPlatinum4 from "../../images/original/custom/Image4-Platinum.jpg";

import ecobb1 from "../../images/ecopro/eco-bb-img-1.jpg"

import claddingMidnightoard from "../../images/cladding/cladding-midnight-board.jpg";

import screeningMahoBoard from "../../images/screening/screening-maho-board.jpg";
import screeningMaho1 from "../../images/screening/screening-maho-img-1.jpg";
import screeningMaho2 from "../../images/screening/screening-maho-img-3.jpg";
//import screeningMaho3 from "../../images/screening/screening-maho-img-5.jpg";
import screeningMaho4 from "../../images/screening/screening-maho-img-6.jpg";

import screeningWeathBoard from "../../images/screening/screening-weath-board.jpg";
import screeningWeath1 from "../../images/screening/screening-weath-img-1.jpg";
import screeningWeath2 from "../../images/screening/screening-weath-img-3.jpg";
import screeningWeath3 from "../../images/screening/screening-weath-img-5.jpg";
import screeningWeath4 from "../../images/screening/screening-weath-img-6.jpg";

import screeningSlateBoard from "../../images/screening/screening-slate-board.jpg";
import screeningSlate1 from "../../images/screening/screening-slate-img-1.jpg";
import screeningSlate2 from "../../images/screening/screening-slate-img-3.jpg";
import screeningSlate3 from "../../images/screening/screening-slate-img-5.jpg";
import screeningSlate4 from "../../images/screening/screening-slate-img-6.jpg";

import screeningWalnutBoard from "../../images/screening/screening-walnut-board.jpg";
import screeningWalnut1 from "../../images/screening/screening-walnut-img-1.jpg";
import screeningWalnut2 from "../../images/screening/screening-walnut-img-3.jpg";
import screeningWalnut3 from "../../images/screening/screening-walnut-img-5.jpg";
import screeningWalnut4 from "../../images/screening/screening-walnut-img-6.jpg";

import screeningMidnightTile from "../../images/screening/screening-midnight-tile.jpg";
import screeningMidnightBoard from "../../images/screening/screening-midnight-board.jpg";
import screeningMidnight1 from "../../images/screening/screening-midnight-img-1.jpg";
import screeningMidnight2 from "../../images/screening/screening-midnight-img-3.jpg";
import screeningMidnight3 from "../../images/screening/screening-midnight-img-5.jpg";
import screeningMidnight4 from "../../images/screening/screening-midnight-img-6.jpg";

import screeningbbBoard from "../../images/screening/screening-bb-board.jpg";
import screeningbb1 from "../../images/screening/screening-bb-img-1.jpg";
import screeningbb2 from "../../images/screening/screening-bb-img-3.jpg";
import screeningbb3 from "../../images/screening/screening-bb-img-5.jpg";
import screeningbb4 from "../../images/screening/screening-bb-img-6.jpg";

import screeningCustWhiteBoard from "../../images/screening/custom/custom-white-board.jpg";
import screeningCustWhite1 from "../../images/screening/custom/img1-white.jpg";
import screeningCustWhite2 from "../../images/screening/custom/img2-white.jpg";
import screeningCustWhite3 from "../../images/screening/custom/img3-white.jpg";

import screeningCustBeigeBoard from "../../images/screening/custom/custom-beige-board.jpg";
import screeningCustBeige1 from "../../images/screening/custom/img1-beige.jpg";
import screeningCustBeige2 from "../../images/screening/custom/img2-beige.jpg";
import screeningCustBeige3 from "../../images/screening/custom/img3-beige.jpg";

import screeningCustBrownBoard from "../../images/screening/custom/custom-brown-board.jpg";
import screeningCustBrown1 from "../../images/screening/custom/img1-brown.jpg";
import screeningCustBrown2 from "../../images/screening/custom/img2-brown.jpg";
import screeningCustBrown3 from "../../images/screening/custom/img3-brown.jpg";

import screeningCustDeepTeaBoard from "../../images/screening/custom/custom-deep-tea-board.jpg";
import screeningCustDeepTea1 from "../../images/screening/custom/img1-deep-tea.jpg";
import screeningCustDeepTea2 from "../../images/screening/custom/img2-deep-tea.jpg";
import screeningCustDeepTea3 from "../../images/screening/custom/img3-deep-tea.jpg";

import screeningCustGreyBoard from "../../images/screening/custom/custom-grey-board.jpg";
import screeningCustGrey1 from "../../images/screening/custom/img1-grey.jpg";
import screeningCustGrey2 from "../../images/screening/custom/img2-grey.jpg";
import screeningCustGrey3 from "../../images/screening/custom/img3-grey.jpg";

import screeningCustLightGreyBoard from "../../images/screening/custom/custom-light-grey-board.jpg";
import screeningCustLightGrey1 from "../../images/screening/custom/img1-light-grey.jpg";
import screeningCustLightGrey2 from "../../images/screening/custom/img2-light-grey.jpg";
import screeningCustLightGrey3 from "../../images/screening/custom/img3-light-grey.jpg";

import screeningCustLightTeaBoard from "../../images/screening/custom/custom-light-tea-board.jpg";
import screeningCustLightTea1 from "../../images/screening/custom/img1-light-tea.jpg";
import screeningCustLightTea2 from "../../images/screening/custom/img2-light-tea.jpg";
import screeningCustLightTea3 from "../../images/screening/custom/img3-light-tea.jpg";

import screeningCustPlatinumBoard from "../../images/screening/custom/custom-platinum-board.jpg";
import screeningCustPlatinum1 from "../../images/screening/custom/img1-platinum.jpg";
import screeningCustPlatinum2 from "../../images/screening/custom/img2-platinum.jpg";
import screeningCustPlatinum3 from "../../images/screening/custom/img3-platinum.jpg";

import xsdo1 from "../../images/xtreme-screening/xtreme-screening-do-img-1.jpg";
import xsdo2 from "../../images/xtreme-screening/xtreme-screening-do-img-2.jpg";
import xsdo3 from "../../images/xtreme-screening/xtreme-screening-do-img-3.jpg";
import xsdoBoard from "../../images/xtreme-screening/xtreme-screening-do-board.jpg";

import xsrm1 from "../../images/xtreme-screening/xtreme-screening-rm-img-1.jpg";
import xsrm2 from "../../images/xtreme-screening/xtreme-screening-rm-img-2.jpg";
import xsrm3 from "../../images/xtreme-screening/xtreme-screening-rm-img-3.jpg";
import xsrmBoard from "../../images/xtreme-screening/xtreme-screening-rm-board.jpg";

import xsGraphiteBoard from "../../images/xtreme-screening/xtreme-screening-graphite-board.jpg";
import xsBasaltBoard from "../../images/xtreme-screening/xtreme-screening-basalt-board.jpg";
import xsRiverstoneGreyBoard from "../../images/xtreme-screening/xtreme-screening-riverstonegrey-board.jpg";
import xsAshGreyBoard from "../../images/xtreme-screening/xtreme-screening-ashgrey-board.jpg";
import xsLightOakBoard from "../../images/xtreme-screening/xtreme-screening-lightoak-board.jpg";

const products = [
  {
    productName: "Xtreme Decking",
    colours: [
      {
        name: "xDesert",
        fullName: "Desert Oak",
        swatchURL: xtremeDesertTile,
        imageURLs: [
          xtremeDesert2,xtremeDesert3,xtremeDesert6,xtremeDesert7,xtremeDesertBoard,
        ],
        id: 1,
      },
      {
        name: "xRustic",
        fullName: "Rustic Merbau",
        swatchURL: xtremeRusticTile,
        imageURLs: [
          xtremeRustic2,xtremeRustic3,xtremeRustic6,xtremeRustic7,xtremeRusticBoard,
        ],
        id: 2,
      },
      {
        name: "xGraphite",
        fullName: "Graphite",
        swatchURL: xtremeGraphiteTile,
        imageURLs: [
          xtremeGraphite2,xtremeGraphite3,xtremeGraphite6,xtremeGraphite7,xtremeGraphiteBoard,
        ],
        id: 3,
      },
      {
        name: "xBasalt",
        fullName: "Basalt",
        swatchURL: xtremeBasaltTile,
        imageURLs: [
          xtremeBasalt2,xtremeBasalt3,xtremeBasalt6,xtremeBasalt7,xtremeBasaltBoard,
        ],
        id: 4,
      },
      {
        name: "xAsh",
        fullName: "Ash Grey",
        swatchURL: xtremeAshTile,
        imageURLs: [xtremeAsh2, xtremeAsh3, xtremeAsh6, xtremeAsh7, xtremeAshBoard],
        id: 5,
      },
      {
        name: "xRiverstone",
        fullName: "Riverstone Grey",
        swatchURL: xtremeRiverstoneTile,
        imageURLs: [
          xtremeRiverstone2,xtremeRiverstone3,xtremeRiverstone6,xtremeRiverstone7,xtremeRiverstoneBoard,
        ],
        id: 6,
      },
      {
        name: "xLightOak",
        fullName: "Light Oak",
        swatchURL: xtremeLightOakTile,
        imageURLs: [
          xtremeLightOak2,xtremeLightOak3,xtremeLightOak6,xtremeLightOak7,xtremeLightOakBoard,
        ],
        id: 7,
      },
    ],
  },
  {
    productName: "Original Decking",
    colours: [
      {
        name: "oMahogany",
        fullName: "Mahogany",
        swatchURL: originalMahoTile,
        imageURLs: [
          originalMaho1,originalMaho2,originalMaho3,originalMaho4,originalMahoBoard,
        ],
        id: 1,
      },
      {
        name: "oWeathered",
        fullName: "Weathered Stone",
        swatchURL: originalWeathTile,
        imageURLs: [
          originalWeath1,originalWeath2,originalWeath3,originalWeath4,originalWeathBoard,
        ],
        id: 2,
      },
      {
        name: "oSlate",
        fullName: "Slate Grey",
        swatchURL: originalSlateTile,
        imageURLs: [
          originalSlate1,originalSlate2,originalSlate3,originalSlate4,originalSlateBoard,
        ],
        id: 3,
      },
      {
        name: "oWalnut",
        fullName: "Walnut",
        swatchURL: originalWalnutTile,
        imageURLs: [
          originalWalnut1,originalWalnut2,originalWalnut3,originalWalnut4,originalWalnutBoard,
        ],
        id: 4,
      },
      {
        name: "oBlackBean",
        fullName: "Black Bean",
        swatchURL: originalbbTile,
        imageURLs: [
          originalBB3,originalBB4,originalBB2,originalBB5,originalBBBoard
        ],
        id: 5,
      },
    ],
  },
  {
    productName: "Original Decking Custom Colours",
    colours: [
      {
        name: "oCusWhite",
        fullName: "White",
        swatchURL: originalCustWhiteTile,
        imageURLs: [
          originalCustWhite1,originalCustWhite2,originalCustWhite3,originalCustWhite4,originalCustWhiteBoard,
        ],
        id: 1,
      },
      {
        name: "oCusBrown",
        fullName: "Brown",
        swatchURL: originalCustBrownTile,
        imageURLs: [
          originalCustBrown1,originalCustBrown2,originalCustBrown3,originalCustBrown4,originalCustBrownBoard,
        ],
        id: 2,
      },
      {
        name: "oCusBeige",
        fullName: "Beige",
        swatchURL: originalCustBeigeTile,
        imageURLs: [
          originalCustBeige1,originalCustBeige2,originalCustBeige3,originalCustBeige4,originalCustBeigeBoard,
        ],
        id: 3,
      },
      {
        name: "oCusGrey",
        fullName: "Grey",
        swatchURL: originalCustGreyTile,
        imageURLs: [
          originalCustGrey1,originalCustGrey2,originalCustGrey3,originalCustGrey4,originalCustGreyBoard,
        ],
        id: 4,
      },
      {
        name: "oCusLightGrey",
        fullName: "Light Grey",
        swatchURL: originalCustLightGreyTile,
        imageURLs: [
          originalCustLightGrey1,originalCustLightGrey2,originalCustLightGrey3,originalCustLightGrey4,originalCustLightGreyBoard,
        ],
        id: 5,
      },
      {
        name: "oCusLightTea",
        fullName: "Light Tea",
        swatchURL: originalCustLightTeaTile,
        imageURLs: [
          originalCustLightTea1,originalCustLightTea2,originalCustLightTea3,originalCustLightTea4,originalCustLightTeaBoard,
        ],
        id: 6,
      },
      {
        name: "oCusDeepTea",
        fullName: "Deep Tea",
        swatchURL: originalCustDeepTeaTile,
        imageURLs: [
          originalCustDeepTea1,originalCustDeepTea2,originalCustDeepTea3,originalCustDeepTea4,originalCustDeepTeaBoard,
        ],
        id: 7,
      },
      {
        name: "oCusPlatinum",
        fullName: "Platinum",
        swatchURL: originalCustPlatinumTile,
        imageURLs: [
          originalCustPlatinum1,originalCustPlatinum2,originalCustPlatinum3,originalCustPlatinum4,originalCustPlatinumBoard,
        ],
        id: 8,
      },
    ],
  },
  {
    productName: "EcoPro Decking",
    colours: [
      {
        name: "eBlackBean",
        fullName: "Black Bean",
        swatchURL: originalbbTile,
        imageURLs: [ecobb1, originalBB2, originalBB3, originalBB4, originalBB5, originalBBBoard],
        id: 1,
      },
    ],
  },
  {
    productName: "EcoPro Decking Custom Colours",
    colours: [
      {
        name: "oMahogany",
        fullName: "Mahogany",
        swatchURL: originalMahoTile,
        imageURLs: [
          originalMaho1,originalMaho2,originalMaho3,originalMaho4,originalMahoBoard,
        ],
        id: 1,
      },
      {
        name: "oWeathered",
        fullName: "Weathered Stone",
        swatchURL: originalWeathTile,
        imageURLs: [
          originalWeath1,originalWeath2,originalWeath3,originalWeath4,originalWeathBoard,
        ],
        id: 2,
      },
      {
        name: "oSlate",
        fullName: "Slate Grey",
        swatchURL: originalSlateTile,
        imageURLs: [
          originalSlate1,originalSlate2,originalSlate3,originalSlate4,originalSlateBoard,
        ],
        id: 3,
      },
      {
        name: "oWalnut",
        fullName: "Walnut",
        swatchURL: originalWalnutTile,
        imageURLs: [
          originalWalnut1,originalWalnut2,originalWalnut3,originalWalnut4,originalWalnutBoard,
        ],
        id: 4,
      },
      {
        name: "oCusWhite",
        fullName: "White",
        swatchURL: originalCustWhiteTile,
        imageURLs: [
          originalCustWhite1,originalCustWhite2,originalCustWhite3,originalCustWhite4,originalCustWhiteBoard,
        ],
        id: 5,
      },
      {
        name: "oCusBrown",
        fullName: "Brown",
        swatchURL: originalCustBrownTile,
        imageURLs: [
          originalCustBrown1,originalCustBrown2,originalCustBrown3,originalCustBrown4,originalCustBrownBoard,
        ],
        id: 6,
      },
      {
        name: "oCusBeige",
        fullName: "Beige",
        swatchURL: originalCustBeigeTile,
        imageURLs: [
          originalCustBeige1,originalCustBeige2,originalCustBeige3,originalCustBeige4,originalCustBeigeBoard,
        ],
        id: 7,
      },
      {
        name: "oCusGrey",
        fullName: "Grey",
        swatchURL: originalCustGreyTile,
        imageURLs: [
          originalCustGrey1,originalCustGrey2,originalCustGrey3,originalCustGrey4,originalCustGreyBoard,
        ],
        id: 8,
      },
      {
        name: "oCusLightGrey",
        fullName: "Light Grey",
        swatchURL: originalCustLightGreyTile,
        imageURLs: [
          originalCustLightGrey1,originalCustLightGrey2,originalCustLightGrey3,originalCustLightGrey4,originalCustLightGreyBoard,
        ],
        id: 9,
      },
      {
        name: "oCusLightTea",
        fullName: "Light Tea",
        swatchURL: originalCustLightTeaTile,
        imageURLs: [
          originalCustLightTea1,originalCustLightTea2,originalCustLightTea3,originalCustLightTea4,originalCustLightTeaBoard,
        ],
        id: 10,
      },
      {
        name: "oCusDeepTea",
        fullName: "Deep Tea",
        swatchURL: originalCustDeepTeaTile,
        imageURLs: [
          originalCustDeepTea1,originalCustDeepTea2,originalCustDeepTea3,originalCustDeepTea4,originalCustDeepTeaBoard,
        ],
        id: 11,
      },
      {
        name: "oCusPlatinum",
        fullName: "Platinum",
        swatchURL: originalCustPlatinumTile,
        imageURLs: [
          originalCustPlatinum1,originalCustPlatinum2,originalCustPlatinum3,originalCustPlatinum4,originalCustPlatinumBoard,
        ],
        id: 12,
      },
    ],
  },
  {
    productName: "Cladding",
    colours: [
      {
        name: "cMahogany",
        fullName: "Mahogany",
        swatchURL: originalMahoTile,
        imageURLs: [
          originalMahoBoard,
        ],
        id: 1,
      },
      {
        name: "cWeathered",
        fullName: "Weathered Stone",
        swatchURL: originalWeathTile,
        imageURLs: [
          originalWeathBoard,
        ],
        id: 2,
      },
      {
        name: "cSlate",
        fullName: "Slate Grey",
        swatchURL: originalSlateTile,
        imageURLs: [
          originalSlateBoard,
        ],
        id: 3,
      },
      {
        name: "cWalnut",
        fullName: "Walnut",
        swatchURL: originalWalnutTile,
        imageURLs: [
          originalWalnutBoard,
        ],
        id: 4,
      },
      {
        name: "cMidnight",
        fullName: "Midnight",
        swatchURL: screeningMidnightTile,
        imageURLs: [
          claddingMidnightoard,
        ],
        id: 5,
      },
      {
        name: "cBlackBean",
        fullName: "Black Bean",
        swatchURL: originalbbTile,
        imageURLs: [
          originalBBBoard,
        ],
        id: 6,
      },
      {
        name: "cWhite",
        fullName: "White",
        swatchURL: originalCustWhiteTile,
        imageURLs: [
          originalCustWhiteBoard,
        ],
        id: 7,
      },
      {
        name: "cBrown",
        fullName: "Brown",
        swatchURL: originalCustBrownTile,
        imageURLs: [
          originalCustBrownBoard,
        ],
        id: 8,
      },
      {
        name: "cBeige",
        fullName: "Beige",
        swatchURL: originalCustBeigeTile,
        imageURLs: [
          originalCustBeigeBoard,
        ],
        id: 9,
      },
      {
        name: "cGrey",
        fullName: "Grey",
        swatchURL: originalCustGreyTile,
        imageURLs: [
          originalCustGreyBoard,
        ],
        id: 10,
      },
      {
        name: "cLightGrey",
        fullName: "Light Grey",
        swatchURL: originalCustLightGreyTile,
        imageURLs: [
          originalCustLightGreyBoard,
        ],
        id: 11,
      },
      {
        name: "cLightTea",
        fullName: "Light Tea",
        swatchURL: originalCustLightTeaTile,
        imageURLs: [
          originalCustLightTeaBoard,
        ],
        id: 12,
      },
      {
        name: "cDeepTea",
        fullName: "Deep Tea",
        swatchURL: originalCustDeepTeaTile,
        imageURLs: [
          originalCustDeepTeaBoard,
        ],
        id: 13,
      },
      {
        name: "cPlatinum",
        fullName: "Platinum",
        swatchURL: originalCustPlatinumTile,
        imageURLs: [
          originalCustPlatinumBoard,
        ],
        id: 14,
      },
    ],
  },
  {
    productName: "Screen Fencing",
    colours: [
      {
        name: "cWeathered",
        fullName: "Weathered Stone",
        swatchURL: originalWeathTile,
        imageURLs: [
          screeningWeath1,
          screeningWeath2,
          screeningWeath3,
          screeningWeath4,
          screeningWeathBoard,
        ],
        id: 1,
      },
      {
        name: "cSlate",
        fullName: "Slate Grey",
        swatchURL: originalSlateTile,
        imageURLs: [
          screeningSlate1,
          screeningSlate2,
          screeningSlate3,
          screeningSlate4,
          screeningSlateBoard,
        ],
        id: 2,
      },
      {
        name: "cWalnut",
        fullName: "Walnut",
        swatchURL: originalWalnutTile,
        imageURLs: [
          screeningWalnut1,
          screeningWalnut2,
          screeningWalnut3,
          screeningWalnut4,
          screeningWalnutBoard,
        ],
        id: 3,
      },
      {
        name: "cMidnight",
        fullName: "Midnight",
        swatchURL: screeningMidnightTile,
        imageURLs: [
          screeningMidnight1,
          screeningMidnight2,
          screeningMidnight3,
          screeningMidnight4,
          screeningMidnightBoard,
        ],
        id: 4,
      },
      {
        name: "cBlackBean",
        fullName: "Black Bean",
        swatchURL: originalbbTile,
        imageURLs: [
          screeningbb1,
          screeningbb2,
          screeningbb3,
          screeningbb4,
          screeningbbBoard,
        ],
        id: 5,
      },
    ],
  },
  {
    productName: "Screen Fencing Custom Colours",
    colours: [
      {
        name: "sCusWhite",
        fullName: "White",
        swatchURL: originalCustWhiteTile,
        imageURLs: [
          screeningCustWhite1,screeningCustWhite2,screeningCustWhite3,screeningCustWhiteBoard,
        ],
        id: 1,
      },
      {
        name: "sCusBrown",
        fullName: "Brown",
        swatchURL: originalCustBrownTile,
        imageURLs: [
          screeningCustBrown1,screeningCustBrown2,screeningCustBrown3,screeningCustBrownBoard,
        ],
        id: 2,
      },
      {
        name: "sCusBeige",
        fullName: "Beige",
        swatchURL: originalCustBeigeTile,
        imageURLs: [
          screeningCustBeige1,screeningCustBeige2,screeningCustBeige3,screeningCustBeigeBoard,
        ],
        id: 3,
      },
      {
        name: "sCusGrey",
        fullName: "Grey",
        swatchURL: originalCustGreyTile,
        imageURLs: [
          screeningCustGrey1,screeningCustGrey2,screeningCustGrey3,screeningCustGreyBoard,
        ],
        id: 4,
      },
      {
        name: "sCusLightGrey",
        fullName: "Light Grey",
        swatchURL: originalCustLightGreyTile,
        imageURLs: [
          screeningCustLightGrey1,screeningCustLightGrey2,screeningCustLightGrey3,screeningCustLightGreyBoard,
        ],
        id: 5,
      },
      {
        name: "sCusLightTea",
        fullName: "Light Tea",
        swatchURL: originalCustLightTeaTile,
        imageURLs: [
          screeningCustLightTea1,screeningCustLightTea2,screeningCustLightTea3,screeningCustLightTeaBoard,
        ],
        id: 6,
      },
      {
        name: "sCusDeepTea",
        fullName: "Deep Tea",
        swatchURL: originalCustDeepTeaTile,
        imageURLs: [
          screeningCustDeepTea1,screeningCustDeepTea2,screeningCustDeepTea3,screeningCustDeepTeaBoard,
        ],
        id: 7,
      },
      {
        name: "sCusPlatinum",
        fullName: "Platinum",
        swatchURL: originalCustPlatinumTile,
        imageURLs: [
          screeningCustPlatinum1,screeningCustPlatinum2,screeningCustPlatinum3,screeningCustPlatinumBoard,
        ],
        id: 8,
      },
      {
        name: "cMahogany",
        fullName: "Mahogany",
        swatchURL: originalMahoTile,
        imageURLs: [
          screeningMaho2,
          screeningMaho1,
          screeningMaho4,
          screeningMahoBoard,
        ],
        id: 9,
      },
    ],
  },
  {
    productName: "Xtreme Screen Fencing",
    colours: [
      {
        name: "xsRustic",
        fullName: "Rustic Merbau",
        swatchURL: xtremeRusticTile,
        imageURLs: [
          xsrm1,
          xsrm2,
          xsrm3,
          xsrmBoard,
        ],
        id: 1,
      },
      {
        name: "xsDesert",
        fullName: "Desert Oak",
        swatchURL: xtremeDesertTile,
        imageURLs: [
          xsdo1,
          xsdo2,
          xsdo3,
          xsdoBoard,
        ],
        id: 2,
      }
    ],
  },
  {
    productName: "Xtreme Screen Fencing Custom Colours",
    colours: [
      {
        name: "xscGraphite",
        fullName: "Graphite",
        swatchURL: xtremeGraphiteTile,
        imageURLs: [
          xsGraphiteBoard
        ],
        id: 1,
      },
      {
        name: "xscBasalt",
        fullName: "Basalt",
        swatchURL: xtremeBasaltTile,
        imageURLs: [
          xsBasaltBoard
        ],
        id: 2,
      },
      {
        name: "xscAshGrey",
        fullName: "Ash Grey",
        swatchURL: xtremeAshTile,
        imageURLs: [
          xsAshGreyBoard
        ],
        id: 3,
      },
      {
        name: "xscRiverstoneGrey",
        fullName: "Riverstone Grey",
        swatchURL: xtremeRiverstoneTile,
        imageURLs: [
          xsRiverstoneGreyBoard
        ],
        id: 4,
      },
      {
        name: "xscLightOak",
        fullName: "Light Oak",
        swatchURL: xtremeLightOakTile,
        imageURLs: [
          xsLightOakBoard
        ],
        id: 5,
      }
    ],
  },
];

const ColourExplorer = ({ prdName, filterName, isDeckingCalculator, onValueChange, activeFilter}) => {
  const [productChanged, setProductChanged] = useState(false);
  const [clickedDeckSwatch, setClickedDeckSwatch] = useState(false);
  const [receivedValue, setReceivedValue] = useState('');
  const size = useWindowSize();
  const [isCustom, setIsCustom] = useState(false);
  const excludedProducts = ['Xtreme Decking', 'Cladding'];
  const [activeColour, setActiveColour] = useState({
    name: "xDesert",
    fullName: "Desert Oak",
    swatchURL: xtremeDesertTile,
    imageURLs: [xtremeDesert2, xtremeDesert3, xtremeDesert6, xtremeDesertBoard],
  });

  const [boardFinish, setBoardFinish] = useState("Fine Grain Pattern");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentProduct, setCurrentProduct] = useState({
    productName: "Xtreme Decking",
    colours: [
      {
        name: "xDesert",
        fullName: "Desert Oak",
        swatchURL: xtremeDesertTile,
        imageURLs: [
          xtremeDesert2,
          xtremeDesert3,
          xtremeDesert6,
          xtremeDesert7,
          xtremeDesertBoard,
        ],
        id: 1,
      },
      {
        name: "xRustic",
        fullName: "Rustic Merbau",
        swatchURL: xtremeRusticTile,
        imageURLs: [
          xtremeRustic2,
          xtremeRustic3,
          xtremeRustic6,
          xtremeRustic7,
          xtremeRusticBoard,
        ],
        id: 2,
      },
      {
        name: "xGraphite",
        fullName: "Graphite",
        swatchURL: xtremeGraphiteTile,
        imageURLs: [
          xtremeGraphite2,
          xtremeGraphite3,
          xtremeGraphite6,
          xtremeGraphite7,
          xtremeGraphiteBoard,
        ],
        id: 3,
      },
      {
        name: "xBasalt",
        fullName: "Basalt",
        swatchURL: xtremeBasaltTile,
        imageURLs: [
          xtremeBasalt2,
          xtremeBasalt3,
          xtremeBasalt6,
          xtremeBasalt7,
          xtremeBasaltBoard,
        ],
        id: 4,
      },
      {
        name: "xAsh",
        fullName: "Ash Grey",
        swatchURL: xtremeAshTile,
        imageURLs: [xtremeAsh2, xtremeAsh3, xtremeAsh6, xtremeAsh7, xtremeAshBoard],
        id: 6,
      },
      {
        name: "xRiverstone",
        fullName: "Riverstone Grey",
        swatchURL: xtremeRiverstoneTile,
        imageURLs: [
          xtremeRiverstone2,
          xtremeRiverstone3,
          xtremeRiverstone6,
          xtremeRiverstone7,
          xtremeRiverstoneBoard,
        ],
        id: 7,
      },
      {
        name: "xLightOak",
        fullName: "Light Oak",
        swatchURL: xtremeLightOakTile,
        imageURLs: [
          xtremeLightOak2,
          xtremeLightOak3,
          xtremeLightOak6,
          xtremeLightOak7,
          xtremeLightOakBoard,
        ],
        id: 8,
      },
    ],
  });

  useEffect(() => {
    if (prdName !== "") {
      let paramValue1 = prdName;
      if(clickedDeckSwatch === false){
        handleProductChange(paramValue1); // Pass in the value to existing product change function to activate product / filter.   
      }
    } else {
      return;
    }
  });

  const handleProductChange = (productName) => {
    let activeColour, boardFinish, currentProduct, currentSlide;

    if(productName.includes(" Custom")){
      setIsCustom(true);
    }else{
      setIsCustom(false);
    }

    products.forEach((product) => {
      if (productName === product.productName) {
        currentProduct = product;
        activeColour = product.colours[0];
      }
      setProductChanged(true);
    });

    boardFinish =
      activeColour.fullName === "Desert Oak" ||
      activeColour.fullName === "Rustic Merbau" ||
      activeColour.fullName === "Graphite" ||
      activeColour.fullName === "Basalt" ||
      activeColour.fullName === "Ash Grey" ||
      activeColour.fullName === "Riverstone Grey" ||
      activeColour.fullName === "Light Oak" ||
      activeColour.fullName === "White"
        ? "Fine Grain Pattern"
        : "Coarse Sanded Finish";

    currentSlide = 0;

    setCurrentProduct(currentProduct);
    setActiveColour(activeColour);
    setCurrentSlide(currentSlide);
    setBoardFinish(boardFinish);
  };

  const handleColourChange = (colourName) => {
    setClickedDeckSwatch(true);
    let activeColourVar, boardFinish, currentSlideVar, noOfFinalSlides;
    let noOfInitialSlides = activeColour.imageURLs.length;
    currentProduct.colours.forEach((colour) => {
      if (colourName === colour.name) {
        activeColourVar = colour;
      }
    });

    boardFinish =
      activeColour.fullName === "Desert Oak" ||
      activeColour.fullName === "Rustic Merbau" ||
      activeColour.fullName === "Graphite" ||
      activeColour.fullName === "Basalt" ||
      activeColour.fullName === "Ash Grey" ||
      activeColour.fullName === "Riverstone Grey" ||
      activeColour.fullName === "Light Oak" ||
      activeColour.fullName === "Ash Grey 2" ||
      activeColour.fullName === "Riverstone Grey 2"
        ? "Fine Grain Pattern"
        : "Coarse Sanded Finish";

    noOfFinalSlides = activeColour.imageURLs.length;

    currentSlideVar =
      currentSlide === noOfInitialSlides - 1
        ? (currentSlideVar = noOfFinalSlides - 1)
        : currentSlide;

    setActiveColour(activeColourVar);
    setCurrentSlide(currentSlideVar);
    setBoardFinish(boardFinish);
  };

  const handleChangeViewport = (status) => {
    let currentSlide1 =
      status === "situ" ? 0 : activeColour.imageURLs.length - 1;
    // setCurrentSlide(currentSlide1);
    handleSlideChange(currentSlide1);
  };

  const handleSlideChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  const handleValueChange = (value) => {
    setReceivedValue(value)
    onValueChange(value);
    console.log(receivedValue);
  }

  return (
    <>
      {(activeFilter !=='no') && (
        <Filters
          currentProduct={currentProduct}
          changeProduct={handleProductChange}
          filterName={filterName}
          onValueChange={handleValueChange}
          isCustom={isCustom}
        />
      )}
      <div className="colour-explorer-main-container container-fluid" style={{padding: isDeckingCalculator && '0'}}>
        {isDeckingCalculator === "yes" ?
          <></>
        :
          <div className="colourname-cont">
            <div>
              <ColourName activeColour={activeColour} boardFinish={boardFinish} />
              {(size.width > 769 && !(excludedProducts.includes(currentProduct.productName))) &&
                <CustomColorSelection currentProductName={currentProduct.productName} filterName={filterName} changeProduct={handleProductChange}/>
              }
            </div>
          </div>
        }
        <div className="ce-main-row d-flex">
          <Viewport
            currentProduct={currentProduct}
            products={products}
            activeColour={activeColour}
            currentSlide={currentSlide}
            slideChange={handleSlideChange}
            handleChangeViewport={handleChangeViewport}
          />

          {(size.width <= 768 && !(excludedProducts.includes(currentProduct.productName))) &&
            <div style={{marginBottom : '32px'}}>
              <CustomColorSelection currentProductName={currentProduct.productName} filterName={filterName} changeProduct={handleProductChange}/>
            </div>
          }

          <Swatch
            currentProduct={currentProduct}
            products={products}
            colourChange={handleColourChange}
            changeViewportStatus={handleChangeViewport}
            currentSlide={currentSlide}
            activeColour={activeColour}
            productChanged={productChanged}
            setProductChanged={setProductChanged}
          />
        </div>
      </div>
    </>
  );
};

export default ColourExplorer;
